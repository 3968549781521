import React from 'react'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import Iframe from 'react-iframe'

class Buy extends React.Component {
	render() {
		return (
			<Layout
				head={{
					title: 'Thinking About Buying A Home in Colorado Springs, CO?',
				}}
			>
				<CommonHero pageTitle="Buy Your Dream Home" />
				<main className="bg-white">
					<section id="featured-listings" className="bg-white">
						<div className="container">
							<div className="row">
								<Iframe
									url="https://ppar.com/frameset/links/linked_res.aspx?q=fauxmdqidm1o4m50m1of4mx3"
									position="relative"
									height="1000px"
									styles={{ padding: '0 50px', marginBottom: '65px' }}
								/>
							</div>
						</div>
					</section>
				</main>
			</Layout>
		)
	}
}

export default Buy
